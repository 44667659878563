import { isProduction } from '@utils/isProduction';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { objectify } from 'radash';
import { FeatureFlagsLocales, FeatureFlagsCountries } from './constants';

type ENVS = 'local' | 'qa' | 'preprod' | 'prod';
export function getRetailBaseApiUrl(env: ENVS = 'qa') {
  const urls: Record<ENVS, string> = {
    local:
      'https://retail-customer-api.1.qa.retail.auto1.cloud/v1/retail-customer-gateway/graphql',
    qa: 'https://retail-customer-api.1.qa.retail.auto1.cloud/v1/retail-customer-gateway/graphql',
    preprod:
      'https://api-customer.prod.retail.auto1.cloud/v1/retail-customer-gateway/graphql',
    prod: 'https://api-customer.prod.retail.auto1.cloud/v1/retail-customer-gateway/graphql',
  };

  return urls[env];
}

const query = `
  query FeatureFlags ($names: [String]!, $context: RetailFeatureStatusContextProjectionInput!) {
    data: retailFeaturesStatusV2(names: $names, context: $context) {
      enabled
      name
    }
  }
`;

export type FeatureFlagStatus = {
  __typename?: 'RetailFeatureStatusProjectionV2';
  enabled: boolean;
  name?: string | null;
};

type useFeatureFlagsQueryProps<T> = {
  // Not empty array
  names: [T, ...T[]];
  locale: FeatureFlagsLocales;
  country: FeatureFlagsCountries;
};
export const useFeaturesFlagsQuery = <T extends string>({
  names,
  locale,
  country,
}: useFeatureFlagsQueryProps<T>) => {
  return useQuery({
    queryKey: ['featureFlags', country, locale, ...names],
    queryFn: async () => {
      const {
        data: { data },
      } = await axios<{ data: { data: FeatureFlagStatus[] } }>({
        url: getRetailBaseApiUrl(isProduction() ? 'prod' : 'qa'),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        data: {
          query,
          variables: {
            context: {
              country,
              environment: 'DEFAULT',
              locale,
            },
            names,
          },
        },
      });

      return objectify(
        data.data,
        (item) => item.name ?? '',
        (item) => item.enabled,
      ) as { [K in T]: boolean };
    },
  });
};
