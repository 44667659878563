import {
  AdMgmtInput,
  AutoheroAndWkda,
  FeatureDetailCategory,
  GeneralSectionData,
  Image,
  ImperfectionItem,
  MediaData,
  MediaImages,
  Tag,
  useCheckVersionQuery,
  FeatureDetailItemInput,
} from '@gql_codegen/retail-types';
import { getAdId } from '@hooks/useAdId';
import {
  AUTOHERO_IMAGE_TYPES,
  SERVICE_HISTORY_VALIDATION_MESSAGES,
} from '@src/constants';
import { FormType } from '@src/layout';
import {
  dataURLtoFile,
  fileExtension,
  uploadImageToS3,
} from '@utils/s3-uploader';
import { useMemo } from 'react';
import { isUploaded } from '@src/types/serviceHistoryDocument';
import omit from 'lodash.omit';

interface WarningMessage {
  message: string;
  qaSelector: string;
}

export interface ISaveFormHelpers {
  checkPriceExceedPercentage(newPriceObj: {
    allowedPercentage?: number;
    current: number | null;
    expected: number | null;
    initial: number | null;
  }): boolean;
  checkNewVersion(arg: {
    currentVersion: number;
    currentChecksum: string;
    currentAdId?: string;
  }): Promise<boolean>;

  uploadRotatedImages(data: AutoheroAndWkda): Promise<{
    imperfections: ImperfectionItem[];
    images: MediaImages;
  }>;
  mappingFormData(formData: FormType): AdMgmtInput;
  getWarningMessages(formData: GeneralSectionData): WarningMessage[];
}

export class SaveFormHelpers implements ISaveFormHelpers {
  checkPriceExceedPercentage({
    expected,
    initial,
    current,
    allowedPercentage = 0,
  }: {
    allowedPercentage?: number;
    current: number | null;
    expected: number | null;
    initial: number | null;
  }): boolean {
    const compareInPercents = (
      current: number,
      reference: number,
      percents: number,
    ) => {
      return (Math.abs(current - reference) / reference) * 100 > percents;
    };

    //TODO: Refactor this after discussion with business
    if (!current) return false;

    if (initial) {
      // 10% more than initial
      return compareInPercents(current, initial, allowedPercentage);
    }

    if (expected) {
      // 10% more than expected
      return compareInPercents(current, expected, allowedPercentage);
    }

    return false;
  }
  async checkNewVersion({
    currentVersion,
    currentChecksum,
    currentAdId,
  }: {
    currentVersion: number;
    currentChecksum: string;
    currentAdId?: string;
  }): Promise<boolean> {
    const adId = currentAdId ?? getAdId() ?? '';
    const response = await useCheckVersionQuery.fetcher({ adId })();
    const newVersion = response.adMgmt.version;
    const newChecksum =
      response.adMgmt.autoheroAndWkda.media.data?.checksum ?? '';
    return newVersion !== currentVersion || newChecksum !== currentChecksum;
  }
  async uploadRotatedImages(data: AutoheroAndWkda) {
    const { media, imperfections } = data;
    const { images } = media?.data as MediaData;
    const newImperfections = (
      imperfections?.items || []
    ).slice() as Array<ImperfectionItem>;
    const imperfectionsWithRotatedImages = newImperfections.filter(
      (imperfection) => imperfection.image.original.startsWith('data:image'),
    );
    if (imperfectionsWithRotatedImages.length === 0)
      return {
        imperfections: newImperfections,
        images,
      };
    for (const withRotatedImage of imperfectionsWithRotatedImages) {
      if (!withRotatedImage) continue;
      const { image: oldImage } = withRotatedImage;
      const { original, uploadedUrl, photoId } = oldImage || {};
      const extension = fileExtension(uploadedUrl);
      if (!(original && photoId)) continue;
      const file = dataURLtoFile(original, `file.${extension}`);
      if (file) {
        const uploadedImage = await uploadImageToS3(
          AUTOHERO_IMAGE_TYPES.DAMAGES,
          file,
        );
        if (!uploadedImage || !oldImage) continue;
        const mappedImage: Image = {
          id: uploadedImage.id,
          original: uploadedImage.fullUrl,
          thumbnail: uploadedImage.fullUrl,
          tags: (oldImage?.tags || []).map((tag) => {
            return { ...tag, tagImageId: uploadedImage.id };
          }),
          nonRemovable: false,
          uploadedUrl: uploadedImage.url,
          photoId,
        };

        const allTargetImages = [
          ...images[AUTOHERO_IMAGE_TYPES.EXTERIOR],
          ...images[AUTOHERO_IMAGE_TYPES.INTERIOR],
          ...images[AUTOHERO_IMAGE_TYPES.HIGHLIGHTS],
        ];

        // NOTE Update tags for target images
        allTargetImages
          .flatMap((targetImage) => targetImage?.tags)
          .forEach((targetTag) => {
            const tag = targetTag as Tag;
            if (tag?.tagImageId === oldImage.id) {
              tag.tagImageId = uploadedImage.id;
            }
          });

        withRotatedImage.image = mappedImage;
      }
    }
    return {
      imperfections: newImperfections,
      images,
    };
  }
  mappingFormData(data: FormType): AdMgmtInput {
    const { priceChangeReason, priceChangeComment } = data;
    const exportItems = data.adMgmt.exportOverview.exportItems;
    const haveComingSoon = exportItems.some(
      (expOvrw) => typeof expOvrw.comingSoon === 'boolean',
    );
    // we should avoid sending title to the BE cause it will rewrite title
    // field, which should be dynamic
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      title,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isNonSmokerVehicle,
      showroomAvailability,
      ...mutationGeneralData
    } = data.adMgmt.general.data;
    const exportOverviewData = {
      ...data.adMgmt.exportOverview.data,
      publishingBlockers: data.adMgmt.exportOverview.data.publishingBlockers,
      exportItems: haveComingSoon
        ? exportItems.map((item) => ({
            marketplace: { id: item.marketplace.id },
            comingSoon: item.comingSoon,
          }))
        : undefined,
    };

    const externalUrlsData = data.adMgmt.externalUrls?.data?.type
      ? {
          data: {
            type: data.adMgmt.externalUrls?.data?.type,
            url: data.adMgmt.externalUrls?.data?.url,
          },
        }
      : data.adMgmt.externalUrls;

    // Service history
    const serviceHistory =
      data.adMgmt.general.data?.serviceHistory &&
      data.adMgmt.general.data?.serviceHistory?.records
        ? {
            id: data.adMgmt.general.data?.serviceHistory?.id,
            records: data.adMgmt.general.data?.serviceHistory?.records
              .map((record) => ({
                ...record,
                documents: record.documents.map((document) => ({
                  id: document.id,
                  fileType: document.fileType,
                  fileExt: isUploaded(document) ? document.fileExt ?? '' : '',
                })),
              }))
              .sort(
                (firstRecord, secondRecord) =>
                  +new Date(secondRecord.lastServiceOn) -
                  +new Date(firstRecord.lastServiceOn),
              ),
          }
        : null;

    const getSWData = () => {
      if (data.adMgmt.autoheroAndWkda.secondaryWheels?.data) {
        const { rimsDetails, tiresDetails } =
          data.adMgmt.autoheroAndWkda.secondaryWheels.data;
        return {
          data: {
            ...data.adMgmt.autoheroAndWkda.secondaryWheels.data,
            rimsDetails: {
              // TODO ask BE to accept only values, without labels
              frontLeft: rimsDetails.frontLeft.map(({ label, value }) => ({
                label,
                value,
              })),
              frontRight: rimsDetails.frontRight.map(({ label, value }) => ({
                label,
                value,
              })),
              rearLeft: rimsDetails.rearLeft.map(({ label, value }) => ({
                label,
                value,
              })),
              rearRight: rimsDetails.rearRight.map(({ label, value }) => ({
                label,
                value,
              })),
            },
            tiresDetails: {
              // TODO ask BE to receive only values, without labels
              frontLeft: tiresDetails.frontLeft.map(({ label, value }) => ({
                label,
                value,
              })),
              frontRight: tiresDetails.frontRight.map(({ label, value }) => ({
                label,
                value,
              })),
              rearLeft: tiresDetails.rearLeft.map(({ label, value }) => ({
                label,
                value,
              })),
              rearRight: tiresDetails.rearRight.map(({ label, value }) => ({
                label,
                value,
              })),
            },
          },
        };
      }
      return null;
    };

    const autoscout24 = data.adMgmt.autoscout24?.data
      ? {
          data: {
            ...omit(data.adMgmt.autoscout24.data, ['imagesLimit']),
          },
        }
      : null;
    const mobiledeV2 = data.adMgmt.mobiledeV2?.data
      ? {
          data: {
            ...omit(data.adMgmt.mobiledeV2.data, ['imagesLimit']),
          },
        }
      : null;

    return {
      autoheroAndWkda: {
        featureDetails: {
          data: data.adMgmt.autoheroAndWkda.featureDetails.data.map(
            (category) => ({
              ...category,
              items: category.items.map((item) => ({
                ...omit(item, 'classifieds'),
              })) as FeatureDetailItemInput[],
            }),
          ),
        },
        imperfections: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          items: data.adMgmt.autoheroAndWkda.imperfections,
        },
        media: {
          data: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            images: data.adMgmt.autoheroAndWkda.media.data?.images,
            video: data.adMgmt.autoheroAndWkda.media.data?.video
              ? {
                  originalUrl:
                    data.adMgmt.autoheroAndWkda.media.data?.video.originalUrl,
                }
              : null,
          },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        secondaryWheels: getSWData(),
        specsSpotlight: data.adMgmt.autoheroAndWkda.specsSpotlight.data,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      exportOverview: { data: exportOverviewData },
      general: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data: {
          priceChangeReason,
          priceChangeComment,
          ...mutationGeneralData,
          showroomAvailability:
            typeof showroomAvailability?.available === 'boolean'
              ? showroomAvailability
              : null,
          serviceHistory,
        },
      },
      version: data.adMgmt.version,
      externalUrls: externalUrlsData,
      autoscout24,
      mobiledeV2,
    };
  }

  getWarningMessages(generalSectionData: GeneralSectionData): WarningMessage[] {
    const warningMessages: WarningMessage[] = [];

    if (!generalSectionData.serviceHistory?.records?.length) {
      return warningMessages;
    }

    const lastServiceHistoryRecord =
      generalSectionData.serviceHistory?.records[0];
    const mileage = generalSectionData.mileage?.distance;
    const lastServiceMileage = lastServiceHistoryRecord?.lastServiceMileage;
    const serviceDate = generalSectionData.lastServiceOn;
    const lastServiceDate = lastServiceHistoryRecord?.lastServiceOn;

    if (mileage !== lastServiceMileage) {
      warningMessages.push({
        message: SERVICE_HISTORY_VALIDATION_MESSAGES.CHANGE_MILEAGE,
        qaSelector: 'service-history-mileage-match',
      });
    }

    if (!serviceDate) {
      warningMessages.push({
        message: SERVICE_HISTORY_VALIDATION_MESSAGES.ADD_LAST_SERVICE_ON,
        qaSelector: 'service-history-add-last-service-on',
      });

      return warningMessages;
    }

    if (serviceDate !== lastServiceDate) {
      warningMessages.push({
        message: SERVICE_HISTORY_VALIDATION_MESSAGES.MATCH_LAST_SERVICE_ON,
        qaSelector: 'service-history-match-last-service-on',
      });

      return warningMessages;
    }

    return warningMessages;
  }

  checkItemsHaveNewTranslations(categories: FeatureDetailCategory[]): boolean {
    return categories.some((category) =>
      category.items.some((item) => item.isNewTranslation),
    );
  }
}

const useSaveFormHelpers = (): SaveFormHelpers => {
  return useMemo(() => new SaveFormHelpers(), []);
};

export default useSaveFormHelpers;
