import { usePageData } from '@hooks/usePageData';
import { Col, Row, Form } from 'antd';
import SelectControlled from '@components/formControlled/SelectControlled';
import { useAppForm } from '@hooks/useAppForm';

export const SpecsSpotlight = (): JSX.Element => {
  const { data, isLoading } = usePageData();
  const {
    control,
    formState: { errors },
  } = useAppForm();

  if (isLoading || !data) return <></>;
  const { specSpotlightOptions } = data.adMgmt.autoheroAndWkda.specsSpotlight;

  return (
    <>
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            label="Quality overview"
            colon={false}
            labelCol={{
              span: 24,
            }}
            required
            validateStatus={
              errors.adMgmt?.autoheroAndWkda?.specsSpotlight?.data
                ?.topHighlights
                ? 'error'
                : 'success'
            }
            help={
              <div data-qa-selector="specs-spotlight-quality-overview-error">
                {
                  errors.adMgmt?.autoheroAndWkda?.specsSpotlight?.data
                    ?.topHighlights?.message
                }
              </div>
            }
          >
            <SelectControlled
              data-qa-selector="qualityOverviewSelect"
              controllerProps={{
                name: 'adMgmt.autoheroAndWkda.specsSpotlight.data.topHighlights',
                control,
              }}
              mode="multiple"
              options={specSpotlightOptions.topHighlightsOptions}
              maxCount={6}
            ></SelectControlled>
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            label="Top Features"
            colon={false}
            labelCol={{
              span: 24,
            }}
            required
            validateStatus={
              errors.adMgmt?.autoheroAndWkda?.specsSpotlight?.data
                ?.topFeatureDetails
                ? 'error'
                : 'success'
            }
            help={
              <div data-qa-selector="general-fuel-type-explain-error">
                {
                  errors.adMgmt?.autoheroAndWkda?.specsSpotlight?.data
                    ?.topFeatureDetails?.message
                }
              </div>
            }
          >
            <SelectControlled
              data-qa-selector="topHighlightsSelect"
              controllerProps={{
                name: 'adMgmt.autoheroAndWkda.specsSpotlight.data.topFeatureDetails',
                control,
              }}
              mode="multiple"
              options={specSpotlightOptions.topFeaturesOptions}
              maxCount={6}
            ></SelectControlled>
          </Form.Item>
        </Col> */}
      </Row>
    </>
  );
};
